import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '../app/hooks'

type RequireAuthProps = {
    children: JSX.Element
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
    const auth = useAppSelector(values => values.auth)
    const location = useLocation()

    if (!auth.user) {
        return <Navigate to="/signin" state={{ from: location }} replace />
    }
    return children
}