import React from 'react';
import {
    Box,
    Flex,
    Link,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useDisclosure,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

interface NavbarProps {
    onSignout: () => void
}

export const Navbar: React.FC<NavbarProps> = ({ onSignout }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                <Box>truoffer</Box>

                <Flex alignItems={'center'}>
                    <Link as={RouterLink} color="blue" fontWeight="semibold" to='/support' mr={4}>
                        Live Support
                    </Link>
                    <Menu>
                        <MenuButton fontWeight="semibold" cursor='pointer' onClick={isOpen ? onClose : onOpen} >
                            My Account {isOpen ? <ChevronUpIcon fontSize='lg' /> : <ChevronDownIcon fontSize='lg' />}
                        </MenuButton>
                        <MenuList>
                            <MenuItem as={RouterLink} to='/applications'>My applications</MenuItem>
                            <MenuItem as={RouterLink} to='/profile'>Change email</MenuItem>
                            <MenuItem as={RouterLink} to='/profile'>Change password</MenuItem>
                            <MenuItem onClick={onSignout}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
        </Box>
    );
}