import * as React from 'react';
import { Box, Button, Text, VStack } from '@chakra-ui/react';

type Choice = {
    title: string
    nextQuestion: number
    isSelected: boolean
}

type Question = {
    id: number
    title: string
    choices: Choice[]
}

type QuestionnaireProps = {
    questions: Question[]
    selectChoice: (questionId: number, choiceIndex: number) => void
}

export const QuestionnaireV2 = ({ questions, selectChoice }: QuestionnaireProps) => {
    const handleSelectChoice = (questionId: number, choiceIndex: number) => {
        selectChoice(questionId, choiceIndex)
    }
    return (
        <Box w='100%' p='16' bg='white' boxShadow='lg' color='gray.800' borderRadius='xl'>
            {questions.map(question => (
                (
                    <React.Fragment key={question.id}>
                        <Text fontSize={'4xl'}>
                            <strong>{question.title}</strong>
                        </Text>
                        <VStack spacing={4} alignItems='flex-start'>
                            {question.choices.map((choice, index) => (
                                <Button
                                    key={`choice-${question.id}-${index}`}
                                    size='lg'
                                    variant='solid'
                                    color='#241BB8'
                                    isActive={choice.isSelected}
                                    _active={{ bg: '#241BB8', color: 'white' }}
                                    _hover={{ bg: '#241BB8', color: 'white' }}
                                    onClick={() => handleSelectChoice(question.id, index)}
                                >
                                    {choice.title}
                                </Button>
                            ))}
                        </VStack>
                    </React.Fragment>
                )
            ))}
        </Box >
    )
}