import * as React from "react"
import { Progress, Container, Box, Stack } from "@chakra-ui/react"

import { DashboardLayout } from "../layouts/DashboardLayout"
import { QuestionnaireV2 } from "../components/QuestionnaireV2"


// Q1
// a. Moana
// b. Others specify: _____

// QUESTIONS PANEL 1
// Panel { 
//     id: number
//     name: string
//     order: number
// }
const QUESTIONS = [
    // Nested Questions (@James)
    // q1 {
    //     questions: {q2,q3}
    // }


    // Mutliple choicer with others textinput

    // Flaw: Cannot show multiple questions on condition
    // Both questions should be answer before proceeding?
    // What if text input?
    // Showing default questions (Show on load)
    // Multiple Answers for input? Page 18 (Possible array, or comma-separated)
    //  q7 - county 
    //  a - { sagot : ['san mateo', 'rizal']}

    // questio type input
    // inputFields[]: { fieldName: 'firstName', fieldLabel: 'First Name', fieldType: 'date/text'}

    // question type: input
    // inputFields instead of choices
    // inputFields[]: { fieldName: 'firstName', fieldLabel: 'First Name', fieldType: 'date/text'}
    {
        id: 1,
        // type: string [multiplechoice, textinput]
        // answerType: 
        // order: number
        // default: true
        isActive: true,
        title: 'Where are you in the buying process?',
        choices: [
            {
                // order: 1
                // title: 'Signed purchase agreement', nextQuestion: [2, 3], isSelected: false
                title: 'Signed purchase agreement', nextQuestion: 2, isSelected: false
            },
            { title: 'Offer pending/found property', nextQuestion: 3, isSelected: false },
            { title: 'Buying 2-6 months', nextQuestion: 2, isSelected: false },
            { title: 'Researching options', nextQuestion: 3, isSelected: false },
        ]
    },
    {
        id: 2,
        isActive: false,
        title: 'Are you currently working with a real estate agent?',
        choices: [
            { title: 'Yes', nextQuestion: 4, isSelected: false },
            { title: 'No', nextQuestion: 5, isSelected: false },
        ]
    },
    {
        id: 3,
        isActive: false,
        title: 'Whhich countries do you intend purcasing in?',
        choices: [
            { title: 'Yes', nextQuestion: 6, isSelected: false },
            { title: 'No', nextQuestion: 7, isSelected: false },
        ]
    },
    {
        id: 4,
        type: 'input',
        isActive: false,
        title: 'Agent Info',
        inputFields: [
            { fieldName: 'firstName', fieldLabel: 'First Name', fieldType: 'text' },
            { fieldName: 'lastName', fieldLabel: 'Last Name', fieldType: 'text' }
        ],
        choices: [
            { title: 'Yes', nextQuestion: 8, isSelected: false },
            { title: 'No', nextQuestion: 8, isSelected: false },
        ]
    },
    {
        id: 5,
        isActive: false,
        title: 'Joint',
        choices: [
            { title: 'Yes', nextQuestion: 8, isSelected: false },
            { title: 'No', nextQuestion: 8, isSelected: false },
        ]
    },
    {
        id: 6,
        isActive: false,
        title: 'Individual',
        choices: [
            { title: 'Yes', nextQuestion: 8, isSelected: false },
            { title: 'No', nextQuestion: 8, isSelected: false },
        ]
    },
    {
        id: 7,
        isActive: false,
        title: 'Family',
        choices: [
            { title: 'Yes', nextQuestion: 8, isSelected: false },
            { title: 'No', nextQuestion: 8, isSelected: false },
        ]
    },
]


const getActiveChoicesId = (questions: typeof QUESTIONS) => {
    const activeQuestions = questions.filter(question => question.isActive)
    const activeChoices = activeQuestions.filter(question =>
        question.isActive
    ).map(question => question.choices.filter(choice => choice.isSelected).map(choice => choice.nextQuestion)).flat()
    return activeChoices
}

export const NewApplication = () => {
    const [questions, setQuestions] = React.useState(QUESTIONS)

    const selectChoice = (questionId: number, choiceIndex: number) => {
        const questionsCopy = [...questions]
        const updatedQuestionIndex = questionsCopy.findIndex(question => question.id === questionId)
        // Reset Choices for Specified Question
        questionsCopy[updatedQuestionIndex].choices = questionsCopy[updatedQuestionIndex].choices.map(choice => ({
            ...choice,
            isSelected: false
        }))
        // Set Active for Selected Choice
        questionsCopy[updatedQuestionIndex].choices[choiceIndex] = {
            ...questionsCopy[updatedQuestionIndex].choices[choiceIndex],
            isSelected: true
        }

        // Make next question active
        const nextQuestionIndex = questionsCopy.findIndex(question => question.id === questionsCopy[updatedQuestionIndex].choices[choiceIndex].nextQuestion)
        console.log(questionsCopy)
        questionsCopy[nextQuestionIndex].isActive = true
        setQuestions(questionsCopy)
    }

    const activeChoices = [1, ...getActiveChoicesId(questions)]
    const activeQuestions = questions.filter(question => question.isActive && activeChoices.includes(question.id))
    return (
        <DashboardLayout>
            <React.Fragment>
                <Progress value={20} colorScheme='blue' />
                <Container maxW='6xl' py='8'>
                    <Stack spacing='16px' alignItems='center'>
                        <Box>
                            <QuestionnaireV2
                                questions={activeQuestions}
                                selectChoice={selectChoice}
                            />
                        </Box>
                    </Stack>
                </Container>
            </React.Fragment>
        </DashboardLayout >
    )
}
