import * as React from "react"
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { RequireAuth } from '../components/RequireAuth'
import { Signin } from '../pages/Signin'
import { Signup } from '../pages/Signup'
import { NotFound } from "../pages/NotFound";
import { Applications } from "../pages/Applications";
import { NewApplication } from "../pages/NewApplication";
import { ForgotPassword } from "../pages/ForgotPassword";


export const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Navigate to="/signin" />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route path="/applications" element={
                <RequireAuth>
                    <Applications />
                </RequireAuth>
            } />
            <Route path="/applications/new" element={<NewApplication />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
)
