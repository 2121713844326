import * as React from 'react';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';

type ApplicationProps = {
    title: string
    dateStarted: string
    type: 'inProgress' | 'completed'
}

export const Application = ({ title, dateStarted, type }: ApplicationProps) => {
    const getVariantByType = (type: string) => {
        switch (type) {
            case 'inProgress': return 'outline'
            case 'completed': return 'solid'
        }
    }
    const getColorSchemeByType = (type: string) => {
        switch (type) {
            case 'inProgress': return 'messenger'
            case 'completed': return 'blue'
        }
    }

    return (
        <Box w='100%' bg='white' boxShadow='lg' color='gray.800' borderRadius='xl'>
            <HStack justifyContent={'space-between'} px='16' py='5'>
                <VStack align='flex-start'>
                    <Text fontSize='2xl'><strong>{title}</strong></Text>
                    <Text>{dateStarted}</Text>
                </VStack>
                <Button variant={getVariantByType(type)} w='64' size='lg' colorScheme={getColorSchemeByType(type)}>
                    {type === 'completed' ? 'Download Summary' : 'Continue Application'}
                </Button>
            </HStack>
        </Box>
    )
}