import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface User {
    firstName: string,
    lastName: string,
    email: string,
}

interface SigninPayload {
    email: string,
    password: string
}

interface SignupPayload {
    email: string,
    password: string
}

interface AuthState {
    user: User | null
    token: string | null
    isLoading: boolean
    errorMessage: string | null | undefined
}


const fetchUserFromLocalStorage = () => {
    const rawUser = localStorage.getItem('user')
    if (rawUser)
        return JSON.parse(rawUser)
    return null
}

const userLocal = fetchUserFromLocalStorage()

const initialState: AuthState = {
    user: userLocal ? userLocal.user : null,
    token: userLocal ? userLocal.token : null,
    isLoading: false,
    errorMessage: null
}

export const signupUser = createAsyncThunk(
    'auth/signupUser',
    async (payload: SignupPayload) => {
        const res = await axios.post('https://truoffer-test.herokuapp.com/api/v1/users', {
            first_name: 'Changeme',
            last_name: 'Changeme',
            email: payload.email,
            password: payload.password,
            password_confirmation: payload.password
        })
        return res.data
    }
)

export const signinUser = createAsyncThunk(
    'auth/signinUser',
    async (payload: SigninPayload) => {
        const res = await axios.post('https://truoffer-test.herokuapp.com/api/v1/login', {
            email: payload.email,
            password: payload.password
        })
        return {
            user: {
                id: res.data.user.id,
                firstName: res.data.user.first_name,
                lastName: res.data.user.last_name,
                email: res.data.user.email
            },
            token: res.data.token
        }
    }
)


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setErrorMessage(state, action) {
            state.errorMessage = action.payload
        },
        signout(state) {
            state.user = null
            state.token = null
        }
    },
    extraReducers: (builder) => {
        // Signup 
        builder.addCase(signupUser.pending, (state) => {
            state.isLoading = true
            state.errorMessage = null
        })
        builder.addCase(signupUser.fulfilled, (state) => {
            state.isLoading = false
            state.errorMessage = null
        })
        builder.addCase(signupUser.rejected, (state) => {
            state.isLoading = false
        })
        // Signin
        builder.addCase(signinUser.pending, (state) => {
            state.isLoading = true
            state.errorMessage = null
        })
        builder.addCase(signinUser.fulfilled, (state, action) => {
            state.user = action.payload.user
            state.token = action.payload.token
            state.isLoading = false
            state.errorMessage = null
        })
        builder.addCase(signinUser.rejected, (state) => {
            state.isLoading = false
        })
    }
})

export const { setErrorMessage, signout } = authSlice.actions;
export default authSlice.reducer;