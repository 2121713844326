import * as React from "react"
import {
  ChakraProvider,
  theme
} from "@chakra-ui/react"
import { Router } from './routes/routes'
import { Provider } from "react-redux"
import { store } from "./app/store"

export const App = () => (
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <Router />
    </ChakraProvider>
  </Provider>
)
