import * as React from "react"
import { useFormik } from "formik"
import * as Yup from 'yup'
import { Link, Center, Container, Flex, Text, Input, Button, VStack, FormControl, FormLabel, InputGroup, InputLeftElement, HStack } from "@chakra-ui/react"
import { EmailIcon, LockIcon } from '@chakra-ui/icons'
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { setErrorMessage, signinUser } from '../ducks/auth/auth-slice'
import { useLocalStorage } from "../utilities/hooks/useLocalStorage"

const SigninSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().min(6, 'Password entered is too short').required('Password is required')
})
export const Signin = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { setValue } = useLocalStorage('user', '{}')
    const { isLoading, errorMessage } = useAppSelector(values => values.auth)

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: SigninSchema,
        onSubmit: (values, { setSubmitting }) => {
            dispatch(signinUser(values))
                .unwrap()
                .then((values) => {
                    setValue(values)
                    navigate('/applications')
                }).catch(() => {
                    dispatch(setErrorMessage('Sorry, your password was incorrect. Please double-check your password.'))
                    setSubmitting(false)
                })
        },
    })

    return (
        <Flex h={'100vh'}>
            <Center flex='1' bg='gray.700' color={'white'}>
                <Container>
                    <Text fontSize={'5xl'}>Sign in to your account</Text>
                    <Text>Welcome back! Sign in to get a free quote on your loan</Text>
                </Container>
            </Center>
            <Center flex='1'>
                <Container>
                    <form onSubmit={formik.handleSubmit}>
                        <VStack spacing={4} align='flex-start'>
                            <FormControl>
                                <FormLabel htmlFor="email">Email Address</FormLabel>
                                {formik.touched.email && formik.errors.email &&
                                    <Text fontSize={'sm'} color={'red'}>{formik.errors.email}</Text>
                                }
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='gray.300'
                                        fontSize='1.2em'
                                        children={<EmailIcon />}
                                    />
                                    <Input
                                        autoComplete="on"
                                        id="email"
                                        name="email"
                                        type="email"
                                        variant="filled"
                                        placeholder="Enter email address"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                {formik.touched.password && formik.errors.password &&
                                    <Text fontSize={'sm'} color={'red'}>{formik.errors.password}</Text>
                                }
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='gray.300'
                                        fontSize='1.2em'
                                        children={<LockIcon />}
                                    />
                                    <Input
                                        autoComplete="on"
                                        id="password"
                                        name="password"
                                        type="password"
                                        variant="filled"
                                        placeholder="Enter password"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                    />
                                </InputGroup>
                            </FormControl>
                            <Link to='/forgot-password' as={RouterLink} fontSize={'sm'} color={'gray.500'}>Trouble signing in?</Link>
                            <HStack spacing={8}>
                                <Button isLoading={isLoading} disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting} type="submit">Sign in</Button>
                                {errorMessage && (
                                    <Text fontSize={'sm'} color={'red'}>{errorMessage}</Text>
                                )}
                            </HStack>
                            <Link to='/signup' as={RouterLink} fontSize={'sm'} color={'gray.500'}>Don't have an account? <strong>Signup</strong></Link>
                        </VStack>
                    </form>
                </Container>
            </Center>
        </Flex >
    )
}