import * as React from "react"
import { Container, Flex, Link, Stack, Text } from "@chakra-ui/react"
import { Link as RouterLink } from 'react-router-dom'

import { Application } from "../components/Application"
import { DashboardLayout } from "../layouts/DashboardLayout"
import { ArrowForwardIcon } from "@chakra-ui/icons"

import { useAppSelector } from '../app/hooks'

export const Applications = () => {
    const user = useAppSelector(values => values.auth.user);

    return (
        <DashboardLayout>
            <React.Fragment>
                <Container maxW='4xl' pt='16'>
                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                        {user && (
                            <Text fontSize={'4xl'}><strong>Welcome, {user.firstName} {user.lastName}!</strong></Text>
                        )}
                        <Link as={RouterLink} to='new' style={{ textDecoration: 'none' }}>
                            <strong>Start a New Application<ArrowForwardIcon fontSize={'xl'} /></strong>
                        </Link>
                    </Flex>
                    <Stack spacing='16px' py={8}>
                        <Application title='Individual Application' dateStarted="last Modified on February 05, 2022" type="inProgress" />
                        <Application title='Individual Application' dateStarted="Already completed" type="completed" />
                        <Application title='Joint Application' dateStarted="Already completed" type="completed" />
                    </Stack>
                </Container>
            </React.Fragment>
        </DashboardLayout>
    )

}